import React, { useEffect, useState } from 'react';

import AnimationsBoxes from '../components/Animations';
import Blog from '../components/Blog';
import Formulary from '../components/Formulary';
import { I18nProvider } from '@lingui/react';
import Languages from '../components/Languages';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import Teams from '../components/Teams';
import { Trans } from '@lingui/macro';
import Typical from 'react-typical';
import What from '../components/What';
import Who from '../components/Who';
import Work from '../components/Work';
import catalogES from '../locales/es/messages';
import logo1x from '../assets/images/logo@1x.png';
import logo1xwebp from '../assets/images/logo@1xwebp.webp';
import logo2x from '../assets/images/logo@2x.png';
import logo2xwebp from '../assets/images/logo@2xwebp.webp';
import logo3x from '../assets/images/logo@3x.png';
import logo3xwebp from '../assets/images/logo@3xwebp.webp';
import logo4x from '../assets/images/logo@4x.png';
import logo4xwebp from '../assets/images/logo@4xwebp.webp';
import logo5x from '../assets/images/logo@5x.png';
import logo5xwebp from '../assets/images/logo@5xwebp.webp';

const IndexPage = ({ data }) => {
  const { allMarkdownRemark } = data;
  const [translate] = useState(false);
  const buttonActive =
    typeof window !== `undefined` &&
    JSON.parse(window.localStorage.getItem('buttonActive'));

  const trans = typeof window !== `undefined` && window.navigator.languages[1];

  if (trans === 'en' && buttonActive === null) {
    window.location.href = `${window.location.origin}/`;
  }
  useEffect(() => {
    typeof window !== `undefined` &&
      window.localStorage.setItem('translate', translate);
  });

  return (
    <I18nProvider language="es" catalogs={{ es: catalogES }}>
      <Layout>
        <Languages lang="es" translate={translate} />
        <section id="banner" style={{ position: 'relative', zIndex: '10' }}>
          <AnimationsBoxes />
          <div className="inner" style={{ zIndex: '10' }}>
            <div className="logo">
              <picture>
                <source
                  srcset={`${logo1xwebp} 833w, ${logo2xwebp} 1667w, ${logo3xwebp} 2500w, ${logo4xwebp} 3334w, ${logo5xwebp} 4167w`}
                  className="logo"
                  type="image/webp"
                />
                <img
                  srcset={`${logo1x} 833w, ${logo2x} 1667w, ${logo3x} 2500w, ${logo4x} 3334w, ${logo5x} 4167w`}
                  alt=""
                  style={{ marginBottom: '7vh' }}
                />
              </picture>
            </div>
            {/* <p>{config.subHeading}</p> */}
            <div>
              <p style={{ fontSize: '1.1em', fontWeight: 500 }}>
                <Trans>We specialize in</Trans>
                <Typical
                  loop={Infinity}
                  wrapper="b"
                  steps={[
                    ' desarrollo móvil 📱',
                    1500,
                    ' desarrollo web 💻',
                    1500,
                  ]}
                />
              </p>
            </div>

            <ul className="actions special">
              <li>
                <Scroll type="id" element="one">
                  <a
                    href="/#"
                    className="button primary"
                    style={{ marginTop: '7vh' }}
                  >
                    <Trans>Explore</Trans>
                  </a>
                </Scroll>
              </li>
            </ul>
          </div>
          <Scroll type="id" element="one">
            <a href="#one" className="more" style={{ color: '#FFFFFF' }}>
              <Trans>Learn More</Trans>
            </a>
          </Scroll>
        </section>
        <section id="one" className="wrapper style1 special">
          <Who />
        </section>

        <section
          id="two"
          className="wrapper alt "
          style={{ backgroundColor: '#38AEEE' }}
        ></section>

        <section className="spotlight " style={{ backgroundColor: '#38AEEE' }}>
          <What />
        </section>

        <section className="spotlight " style={{ backgroundColor: '#38AEEE' }}>
          <Work />
        </section>

        <section
          id="two-and-half"
          className="wrapper style1 special"
          style={{ position: 'relative', zIndex: '0' }}
        >
          <Teams />
        </section>

        <section id="three" className="wrapper style4 special">
          <div className="inner">
            <header className="major">
              <h2
                style={{
                  fontSize: '1.4em',
                  fontStyle: 'bold',
                  fontWeight: 900,
                }}
              >
                <Trans>OUR SERVICES</Trans>
              </h2>
              {/* <p>
            Aliquam ut ex ut augue consectetur interdum. Donec amet imperdiet
            eleifend
            <br />
            fringilla tincidunt. Nullam dui leo Aenean mi ligula, rhoncus
            ullamcorper.
          </p> */}
            </header>

            {/* h3 & p tags' style modified here because icon not showing properly otherwise */}

            <ul className="features">
              <li className="icon fa fa-paper-plane">
                <h3
                  style={{
                    fontFamily: 'sans-serif',
                    fontSize: '1.3em',
                    fontWeight: 900,
                  }}
                >
                  <Trans>Mobile App development</Trans>
                </h3>
                <p style={{ fontFamily: 'sans-serif', fontSize: '18px' }}>
                  <Trans>
                    We deliver engaging & user-oriented mobile applications with
                    business value. (iOS, Android and React Native)
                  </Trans>
                </p>
              </li>
              <li className="icon solid fa fa-laptop">
                <h3
                  style={{
                    fontFamily: 'sans-serif',
                    fontSize: '1.3em',
                    fontWeight: 900,
                  }}
                >
                  <Trans>Web App development</Trans>
                </h3>
                <p style={{ fontFamily: 'sans-serif', fontSize: '18px' }}>
                  <Trans>
                    We engineer web solutions. Front–end & back–end development.
                    Ruby and Rails, ReactJS and Java.
                  </Trans>
                </p>
              </li>
              <li className="icon solid fa fa-code">
                <h3
                  style={{
                    fontFamily: 'sans-serif',
                    fontSize: '1.3em',
                    fontWeight: 900,
                  }}
                >
                  <Trans>Wireframing & design</Trans>
                </h3>
                <p style={{ fontFamily: 'sans-serif', fontSize: '18px' }}>
                  <Trans>
                    Every solution needs a beautiful design! We create intuitive
                    interfaces (UI design) and design great user experience on
                    their journey (UX design).
                  </Trans>
                </p>
              </li>
              <li className="icon solid fa fa-headphones-alt">
                <h3
                  style={{
                    fontFamily: 'sans-serif',
                    fontSize: '1.3em',
                    fontWeight: 900,
                  }}
                >
                  <Trans>IT CONSULTING</Trans>
                </h3>
                <p style={{ fontFamily: 'sans-serif', fontSize: '18px' }}>
                  <Trans>
                    We help you steer the way to your digital transformation
                    helping you choosing the best technology, according to the
                    business insights.
                  </Trans>
                </p>
              </li>
            </ul>
          </div>
        </section>

        <section id="four" className="wrapper style4  special">
          <Formulary />
        </section>

        <section id="five" className="wrapper style1 special">
          <Blog
            title={'Blog'}
            subtitle={'Nuestras últimas Publicaciones'}
            data={allMarkdownRemark}
          />
        </section>
      </Layout>
    </I18nProvider>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query blogPostsAndBlogPosts {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 100
      filter: { frontmatter: { language: { eq: "es" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            language
            thumbnail {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 150)
        }
      }
    }
  }
`;
